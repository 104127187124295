import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sourceSelect", "target"]

  connect() {
    $(this.sourceSelectTarget).on('change', function () {
      this.updateTargets(this.targetTargets);
    }.bind(this));
    this.updateTargets(this.targetTargets);
  }

  updateTargets(selects) {
    const options = this.options();
    selects.forEach(function (select) {
      const value = (select.options[select.selectedIndex] || {}).value;
      select.innerHTML = options;
      if (value) {
        [...select.options].forEach(function (option) {
          option.selected = value == option.value;
        });
      }
    });
  }

  options() {
    return [...this.sourceSelectTarget.options].reduce(function (html, option) {
      if (option.selected) {
        const new_option = document.createElement("option");
        new_option.text = option.text;
        new_option.value = option.value;
        return html + new_option.outerHTML;
      } else {
        return html;
      }
    }, []);
  }

  selectsConnected(event) {
    const selects = event.target.querySelectorAll("select[data-select-options-synchronizer-target=\"target\"]")
    this.updateTargets(selects)
  }
}
