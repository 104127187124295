// Original checkboxes-actions-controller from 2019
// Append selected checkboxes values (ex. record ids) to clicked `link_to` or `button_to`

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "checkbox",  // checkboxes with values
    "preview", // element for displaying checkboxes values
    "hideable", // container element for preview and actions; hidden if values are empty
  ]

  connect() {
    this.values = new Set();
  }

  onToggleAll(event) {
    this.checkboxTargets.forEach(checkbox => {
      this.toggleCheckbox(checkbox, event.target.checked)
    });
  }

  onToggleCheckbox(event) {
    this.toggleCheckbox(event.target, event.target.checked)
  }

  // update link href or form inputs before sending request
  // appending checkboxes values into searchParams or creating form inputs
  onActionCall(event) {
    const node = event.target
    const handler = node.nodeName === 'FORM'
      ? (name, value) => {
        const newInput = document.createElement('input')
        Object.assign(newInput, {
          type: 'hidden',
          name: name,
          value: value,
          autocomplete: 'off'
        })
        node.append(newInput)
      }
      : node.nodeName === 'A'
      ? (name, value) => {
        const newURL = new URL(node.href);
        newURL.searchParams.append(name, value)
        node.href = newURL
      }
      : console.error("unsupported element", event.target.nodeName)

    // param name for value
    const paramKey = node.dataset.paramKey;
    // constants appended after each value ex. "const1,v1;const2,v2"
    const paramConst = node.dataset.paramConst
    const parsedParamConst = paramConst.split(';').map(s => s.split(','))

    if (paramKey) {
      this.values.forEach((value) => {
        handler(paramKey, value)
        if (paramConst) {
          parsedParamConst.forEach(([k, v]) => handler(k, v))
        }
      });
    }
    // event.preventDefault();
  }

  toggleCheckbox(checkbox, toggle) {
    if (toggle)
      this.addCheckbox(checkbox)
    else
      this.removeCheckbox(checkbox)
  }

  toggleHideable() {
    if (this.hideableTarget) {
      this.hideableTarget.classList.toggle('d-none', !this.values.size)
    }
  }

  addCheckbox(checkbox) {
    checkbox.checked = true;
    this.values.add(checkbox.value);
    this.displayValues();
    this.toggleHideable();
  }

  removeCheckbox(checkbox) {
    checkbox.checked = false;
    this.values.delete(checkbox.value);
    this.displayValues();
    this.toggleHideable();
  }

  displayValues() {
    if (this.previewTarget) {
      const content = Array.from(this.values).join(', ')
      this.previewTarget.innerText = content;
    }
  }
}
