import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTargets.forEach((input) => this.resize(input))
  }

  onInput(event) {
    this.resize(event.target);
  }

  resize(input) {
    const newWidth = this.computeInputWidth(input) + 5;
    input.style.width = newWidth + 'px';
    const isLongInput = parseFloat(window.getComputedStyle(input).maxWidth) < newWidth
    input.classList.toggle('long-input', isLongInput);
  }

  computeInputWidth(input) {
    const canvas = this.canvas || document.createElement('canvas');
    const context = canvas.getContext('2d');
    const inputStyle = window.getComputedStyle(input);
    context.font = ['fontWeight', 'fontSize', 'fontFamily']
      .map(style => inputStyle[style]).join(' ')
    context.font = inputStyle.font
    const metrics = context.measureText(input.value);
    const extraWidth = ['paddingLeft', 'paddingRight', 'marginLeft', 'marginRight']
      .reduce((value, style) => value + parseFloat(inputStyle[style]), 0)
    return metrics.width + extraWidth;
  }
}
