import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "dayCheckbox",
    "flatpickrInput"
  ]

  connect() {
    setTimeout(() => {
      this.updateFlatpickrDisable()
    }, 0);
  }

  get flatpickr() {
    return this.flatpickrInputTarget.flatpickr.fp
  }

  dayUpdate(event) {
    this.updateFlatpickrDisable()
  }

  updateFlatpickrDisable() {
    const weekdays = this.dayCheckboxTargets
      .filter((day) => { return !day.checked })
      .map((day) => { return Number(day.dataset.dayIdValue) })

    this.flatpickr.set("disable", [
      (date) => {
        return this.dateInWeekdays(date, weekdays)
      }
    ])

    this.flatpickr.setDate(this.flatpickr.selectedDates.filter(date => {
      return !this.dateInWeekdays(date, weekdays)
    }))
  }

  dateInWeekdays(date, weekdays) {
    return weekdays.some((day) => { return date.getDay() === day })
  }
}
