import { Controller } from "@hotwired/stimulus"
import BasicController from "./nested_form/basic_controller"

// example
// <form data-controller="empty-form-elements" data-action="empty-form-elements#remove">
//   <div data-empty-form-elements-target="toRemoveGroup">  <!-- optional -->
//     <div data-empty-form-elements-target="toRemove"><input data-empty-form-elements-target="valueHolder"></input></div>
//     <div data-empty-form-elements-target="toRemove"><input data-empty-form-elements-target="valueHolder"></input></div>
//   </div>
// </form>

export default class extends Controller {
  static targets = [
    "toRemoveGroup", // optional: element removed if doesn't contain any `toRemoveTargets`
    "toRemove", // element removed if nested `valueHolderTarget` is empty
    "valueHolder" // element with value attribute checked for emptiness
  ]

  remove() {
    this._removeEmptyFormElements()
    this._removeEmptyGroups()
  }

  _removeEmptyFormElements() {
    Array.from(this.valueHolderTargets)
      .filter(el => el.value === "" && !el.disabled)
      .map(el => Array.from(this.toRemoveTargets).find(el2 => el2.contains(el)))
      .filter(el => el !== undefined)
      .forEach(el => BasicController.removeItem(el))
  }

  _removeEmptyGroups() {
    Array.from(this.toRemoveGroupTargets)
      .filter(el =>
        !Array.from(this.toRemoveTargets)
          .filter(el => !BasicController.itemMarkedForDestruction(el))
          .some(el2 => el.contains(el2)))
      .forEach(el => BasicController.removeItem(el))
  }
}
