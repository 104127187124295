import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "group",
    "input"
  ]

  connect() {
    this.dragEl = null
  }

  getValues(group) {
    const inputs = this.inputTargets.filter(input => group.contains(input))
    this.values = inputs.map(e => e.value)
  }

  setValues(group) {
    const inputs = this.inputTargets.filter(input => group.contains(input))
    this.values.forEach((value, index) => {
      if (inputs[index]) {
        inputs[index].value = value
        inputs[index].dispatchEvent(new Event('input'))
      }
    });
  }

  initDrag(event) {
    this.dragEl = this.groupTargets.find(e => e.contains(event.currentTarget))
    this.dragEl.draggable = true
  }

  dragStart(event) {
    if (this.dragEl) {
      this.getValues(event.currentTarget)
    }
  }

  dragOver(event) {
    if (this.dragEl) {
      event.preventDefault()
    }
  }

  drop(event) {
    if (this.dragEl) {
      this.setValues(event.currentTarget)
      this.dragEl.draggable = false
      this.dragEl = null
      event.preventDefault()
    }
  }
}
