import { Controller } from "@hotwired/stimulus"

// collapse-all
// example:
// <div data-controller="collapse-all">
//   <button data-action="collapse-all#expand" type="button">expand all</button>
//   <button data-action="collapse-all#collapse" type="button">collapse all</button>
//   <div data-controller="collapse" data-collapse-all-target="collapse">
//     <a aria-expanded="false" data-collapse-target="button" data-action="collapse#handleToggle" href="#">Toggle</a>
//     <div data-collapse-target="collapsable"></div>
//   </div>
//   <div data-controller="collapse" data-collapse-all-target="collapse">
//     <a aria-expanded="false" data-collapse-target="button" data-action="collapse#handleToggle" href="#">Toggle</a>
//     <div data-collapse-target="collapsable"></div>
//   </div>
// </div>

export default class extends Controller {
  static targets = [
    "collapse" // element with `data-controller="collapse"`
  ]

  expand() {
    this.collapseTargets.forEach(element => {
      element.collapse.toggle(true)
    });
  }

  collapse() {
    this.collapseTargets.forEach(element => {
      element.collapse.toggle(false)
    });
  }
}
