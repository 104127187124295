import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "target"
  ]

  sync(event) {
    const source = event.currentTarget
    this.targetTargets.forEach(target => {
      if (target.dataset.selectSyncName == source.dataset.selectSyncName) {
        if (source.value != "") {
          target.value = source.value
          target.dispatchEvent(new Event("change"))
        }
      }
    })
  }
}