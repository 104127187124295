import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "lang"
  ]

  connect () {
    this.updateHrefs()
  }

  updateHrefs () {
    const l = window.location
    const pathnameWithoutLang = l.pathname.replace(/^\/\w{2}\//, "/")
    this.langTargets.forEach(el => {
      el.href = `/${el.hreflang}${pathnameWithoutLang}${l.search}${l.hash}`
    })
  }
}
