// This code-block controller works with specially annotated HTML like:
//
// <pre data-controller="code-block" data-lang="json">
//   { test: "test"}
// </pre>

import { Controller } from "@hotwired/stimulus"
import { pd } from "pretty-data"

export default class extends Controller {
  connect() {
    const lang = this.element.dataset.lang
    let code = pd[lang](this.element.textContent)
    code = window.Prism.highlight(code, Prism.languages[lang], lang);
    this.element.innerHTML = code
  }
}
