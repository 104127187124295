import { Controller } from '@hotwired/stimulus'

// synchronize selected inputs` values (selectorValue from document or controller element)
// with the target link's searchParams
export default class extends Controller {
  static values = {
    selector: String,
    selectorScoped: Boolean
  }

  connect () {
    this.selectorScope = this.selectorScopedValue ? this.element : document
  }

  sync (e) {
    const target = e.currentTarget
    const els = this.selectorScope.querySelectorAll(this.selectorValue)

    // e.preventDefault(); console.log(target)
    const url = new URL(target.href)
    els.forEach(el => {
      url.searchParams.set(el.name, el.value)
    })
    target.href = url
    // console.log(target)
  }
}
