import { Controller } from "@hotwired/stimulus"
import LocalSettings from "../lib/local_settings"

let collapseUniqueId = 0;

// collapse
// example:
// <div data-controller="collapse">
//   <a aria-expanded="false" data-collapse-target="button" data-action="collapse#handleToggle" href="#">Toggle</a>
//   <div data-collapse-target="collapsable"></div>
// </div>

export default class extends Controller {
  static targets = ["button", "collapsable"]
  static values = {
    settingKey: String // available ["nav.open"]
  }

  connect() {
    this.element.collapse = this;
    this.toggle(this.isExpanded);
    this.assignMissingIdForTargets();
    this.buttonTargets.forEach(button => {
      button.setAttribute("aria-controls", this.collapsableTargets.map(collapsable => { return collapsable.id }).join(" "));
    })
  }

  get isExpanded() {
    return (this.hasSettingKeyValue) ? LocalSettings.getValue(this.settingKeyValue) : this.buttonTarget.getAttribute("aria-expanded") === "true"
  }

  // CSS class name to toggle
  get toggleClass() {
    return this.data.get("toggle-class") || "d-none"
  }

  assignMissingIdForTargets() {
    this.collapsableTargets.forEach(collapsable => {
      if (!collapsable.hasAttribute('id')) {
        collapsable.id = `collapse_${++collapseUniqueId}`;
      }
    });
  }

  toggle(force) {
    const bool = (typeof force === "boolean") ? force : !this.isExpanded;
    if (this.hasSettingKeyValue) LocalSettings.setValue(this.settingKeyValue, bool)
    this.buttonTargets.forEach(button => {
      button.setAttribute("aria-expanded", bool);
    })
    this.collapsableTargets.forEach(collapsable => {
      collapsable.classList.toggle(this.toggleClass, !bool);
      collapsable.dispatchEvent(new Event('collapse:toggled', { "bubbles": true }))
    });
  }

  handleToggle(event) {
    event.preventDefault();
    this.toggle();
  }
}
