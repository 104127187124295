export default class {
  static id = "spinner"

  static on() {
    if (!this.element) {
      document.body.insertAdjacentHTML("beforeend", this.tmp);
    }
  }

  static off() {
    this.element?.remove()
  }

  static get element() {
    return document.getElementById(this.id)
  }

  static get tmp() {
    return `
      <div id="${this.id}" class="modal-backdrop show d-flex justify-content-center align-items-center">
        <div role="status" class="spinner-border text-light"></div>
      </div>
    `
  }
}
