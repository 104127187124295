import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [
    "item",
    "positionInput",
    "destroyInput"
  ]

  connect() {
    if (this.manualSort) {
      this.sortable = Sortable.create(this.element, {
        handle: this.data.get("handle"),
        onUpdate: this.setPositions.bind(this)
      });
    }
  }

  // manualSort returns boolean
  // returns true by default
  get manualSort() {
    return this.data.get("manual-sort") === "false" ? false : true
  }

  setPositions() {
    this.positionInputTargets
      .filter(positionInput => {  // filter removed positionInputTargets
        const item = this.itemTargets
          .find(item => item.contains(positionInput));
        const destroyInput = this.destroyInputTargets
          .find(destroyInput => item.contains(destroyInput));
        return !destroyInput || destroyInput.value !== '1'
      })
      .forEach((position, i) => {
        position.value = i + 1;
      })
  }
}
