import { Controller } from "@hotwired/stimulus"

// <div data-controller="clear">
//   <button data-action="clear#clear"></button>
//   <input type="text" data-clear-target="inputType"></input>
//   <input type="checkbox" data-clear-target="checkboxType"></input>
//   <select data-controller="select2" data-clear-target="select2Type">...</select>
//  <div data-controller="v--index-value-groups" data-clear-target="indexValueGroupType">...</div>
// </div>
export default class extends Controller {
  static targets = [
    "inputType",
    "checkboxType",
    "select2Type",
    "indexValueGroupType"
  ]

  clear() {
    this.inputTypeTargets.forEach(el => {
      el.value = ""
      el.dispatchEvent(new Event('change'))
    });
    this.checkboxTypeTargets.forEach(el => {
      el.checked = false
      el.dispatchEvent(new Event('change'))
    });
    this.select2TypeTargets.forEach(el => {
      el.select2.setValue("")
    });
    this.indexValueGroupTypeTargets.forEach(el => {
      el["v--index-value-groups"].removeAll();
    });
  }
}
