import BasicController from "../nested_form/basic_controller";

export default class extends BasicController {
  static targets = [
    "addItem", // select for adding Items
    "filter"
  ]

  connect() {
    $(this.addItemTarget).select2({
      width: 'max-content'
    })
      .off('select2:selecting')
      .on('select2:selecting', this.optionSelecting.bind(this))
  }

  optionSelecting(jQueryEvent) {
    jQueryEvent.preventDefault();
    const data = jQueryEvent.params.args.data;
    this.addItem(data.text, data.id);
    $(this.addItemTarget).select2('close');
  }

  addItem(value, id) {
    const template = this.templateTarget;
    let content = template.innerHTML.replace(
      new RegExp(this.itemId(), 'g'),
      new Date().valueOf()
    );
    content = content.replace(new RegExp("ID", 'g'), id);
    content = content.replace(new RegExp("TEXT", 'g'), value);

    this.toggleDisable(id)

    // insert at the beginning
    this.itemsTarget.insertAdjacentHTML('beforeend', content);
    this.afterAddItem(this.itemsTarget.lastChild);
    return this.itemsTarget.lastChild;
  }

  removeFilters(event) {
    this.filterTargets.filter((item) => event.target.contains(item)).forEach(filter => {
      this.toggleDisable(filter.value)
    });
  }

  toggleDisable(value) {
    const option = this.addItemTarget.querySelector(`option[value="${value}"]`)
    option.disabled = !option.disabled
    $(this.addItemTarget).trigger("change")
  }
}
