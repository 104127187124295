import BasicController from "./basic_controller";

export default class extends BasicController {
  static targets = ["textarea"]

  edit(event) {
    this.input = document.getElementById(event.currentTarget.dataset.inputId)
    this.textareaTarget.value = this.input.value
    this.open()
    this.textareaTarget.focus()
  }

  updateInput() {
    this.input.value = this.textareaTarget.value
    this.input.focus()
  }

  renameIdPrefix(event) {
    // update data-input-id attribute
    const replaceInputIdPrefix = (node) => {
      const oldPrefix = node.dataset.inputId
      node.dataset.inputId = oldPrefix.replace(event.detail.oldIdPrefix, event.detail.newIdPrefix)
    }
    event.target.querySelectorAll(`[data-input-id^="${event.detail.oldIdPrefix}"]`).forEach(replaceInputIdPrefix)
    event.target.querySelectorAll(`template`).forEach((node) => {
      node.content.querySelectorAll(`[data-input-id^="${event.detail.oldIdPrefix}"]`).forEach(replaceInputIdPrefix)
    })
  }
}
