import CopyInputValueController from "./copy_input_value_controller.js";

export default class extends CopyInputValueController {
  static targets = [
    "indicator1",
    "indicator2"
  ]

  getValues(group) {
    super.getValues(group)
    this.indicator1 = this.indicator1Targets.find(i => group.contains(i)).value
    this.indicator2 = this.indicator2Targets.find(i => group.contains(i)).value
  }

  setValues(group) {
    super.setValues(group)
    this.indicator1Targets.find(i => group.contains(i)).value = this.indicator1
    this.indicator2Targets.find(i => group.contains(i)).value = this.indicator2
  }
}
