import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  run () {
    this.element.querySelectorAll('input,select').forEach(e => {
      if (e.value === '') {
        e.disabled = true
      }
    })
  }
}
