import BasicController from "./basic_controller";

export default class extends BasicController {
  static targets = ["fieldTemplate", "indicatorTemplate", "subfieldTemplate", "list", "form", "field", "submitButton"]

  onUpdate() {
    this.submitButtonTarget.setAttribute('disabled', 'disabled')
    this.updating = true;
    this.close()
    this.formTarget.submit()
  }

  enableButton() {
    if (this.updating !== true) this.submitButtonTarget.removeAttribute('disabled');
  }

  onGoTo(event) {
    this.close()
    const el = document.getElementById(event.currentTarget.dataset.id)
    document.documentElement.scrollTo(0, document.documentElement.scrollTop + el.getBoundingClientRect().y)
    el.focus()
  }

  updateFieldsList() {
    this.listTarget.textContent = ""
    this.fieldTargets.forEach(fieldEl => {
      if (fieldEl.querySelector(`input[name$="[_destroy]"]`).value != "1") {
        const tag = fieldEl.dataset.itemValue
        const indicator1El = fieldEl.querySelector(`input[name$="[indicator1]"]`)
        const indicator2El = fieldEl.querySelector(`input[name$="[indicator2]"]`)
        let indicators = "";

        if (indicator1El) {
          indicators += this.indicatorTemplateTarget.innerHTML
            .replace("INDICATOR_ID", indicator1El.id)
            .replace("INDICATOR_VALUE", indicator1El.value);
        }

        if (indicator2El) {
          indicators += this.indicatorTemplateTarget.innerHTML
            .replace("INDICATOR_ID", indicator2El.id)
            .replace("INDICATOR_VALUE", indicator2El.value);
        }

        const subfields = Array.from(fieldEl.querySelectorAll(`[data-modal--record-submit-target="subfield"]`)).map(subfieldEl => {
          if (subfieldEl.querySelector(`input[name$="[_destroy]"]`).value != "1") {
            const code = subfieldEl.dataset.itemValue
            const input = subfieldEl.querySelector(`input[name$="[value]"]`)
            return this.subfieldTemplateTarget.innerHTML
              .replace("SUBFIELD_CODE", code)
              .replace("SUBFIELD_ID", input.id)
              .replace("SUBFIELD_VALUE", input.value);
          }
        }).join(" ")

        const content = this.fieldTemplateTarget.innerHTML
          .replace("FIELD_TAG", tag)
          .replace("INDICATORS", indicators)
          .replace("SUBFIELDS", subfields)

        this.listTarget.insertAdjacentHTML('beforeend', content)
      }
    });
  }

  onSubmit(event) {
    event.preventDefault()
    this.updateFieldsList()
    this.open()
  }
}
