import { Controller } from "@hotwired/stimulus";
import bsCustomFileInput from 'bs-custom-file-input';


export default class extends Controller {
  connect() {
    this.attach();
    this.element.addEventListener(
      'nested-form:after-insert',
      this.attach.bind(this)
    )
  }

  disconnect() {
    bsCustomFileInput.destroy();
  }

  attach() {
    this.disconnect();
    bsCustomFileInput.init();
  }

}
