import { Controller } from "@hotwired/stimulus"

const ISBN = require("simple-isbn").isbn
const issn = require("issn")

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('[data-action*="validator#isbn"]').forEach((input) => {
      this.isbn({ currentTarget: input })
    })
    this.element.querySelectorAll('[data-action*="validator#issn"]').forEach((input) => {
      this.issn({ currentTarget: input })
    })
  }

  isbn(e) {
    const input = e.currentTarget
    const isValid = ISBN.isValidIsbn(input.value)
    this.toggleValid(input, isValid)
  }

  issn(e) {
    const input = e.currentTarget
    const isValid = issn(input.value)
    this.toggleValid(input, isValid)
  }

  toggleValid(input, isValid) {
    input.classList.toggle('is-invalid', !isValid)
    input.classList.toggle('is-valid', isValid)
  }
}
