import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'formItems', 'formAddItem', 'formSubmit',
    'resultsTable'
  ]

  nextPage() {
    let input = this.findOrAddNextPageInput()

    let id = new Number(
      this.resultsTableTarget.
        querySelector("tbody > tr:last-of-type > th")
        .textContent
    )

    input.value = id + 1
    this.formSubmitTarget.click()
  }

  findOrAddNextPageInput() {
    let nextPageParamName = 'sinceId'

    let findInput = () => {
      let select = Array.from(
        this.formItemsTarget
          .querySelectorAll('select')
      ).find(select => select.options[select.selectedIndex].value === nextPageParamName)

      if (!select)
        return undefined
      else
        return select.closest('fieldset').querySelector('input')
    }

    let addInput = () => {
      this.formAddItemTarget.click()
      let newFormItem = this.formItemsTarget.lastChild
      let select = newFormItem.querySelector("select")
      select.value = nextPageParamName
      let input = newFormItem.querySelector('input')
      return input
    }

    return findInput() || addInput()
  }

}
