import { Controller } from "@hotwired/stimulus"

// Shows / hide `targetTarget` based on `toggleTarget` value
//
// example:
// <div data-controller="display-toggle">
//   <input data-display-toggle-target="toggle" data-action="display-toggle#toggle" type="checkbox">
//   <div data-display-toggle-target="target"> CONTENT HIDDEN / SHOWN </div>
// </div>
export default class extends Controller {
  static targets = [
    "toggle",
    "target"
  ]

  connect() {
    this.toggle()
  }

  toggle(_) {
    const checked = this.toggleTarget.checked
    this.targetTargets.forEach(el =>
      el.classList.toggle('d-none', !checked)
    )
  }
}
