import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.executeClick()
  }

  executeClick() {
    const params = new URLSearchParams(location.search);
    if (params.has('autoclick')) {
      const el = document.getElementById(params.get('autoclick'))
      if (el) {
        params.delete('autoclick')
        history.replaceState({}, '', `${location.pathname}?${params}`);
        setTimeout(() => { el.click() })
      }
    }
  }

  activate(e) {
    const params = new URLSearchParams(location.search);
    params.set('autoclick', e.target.dataset.autoclickElementId)
    history.replaceState({}, '', `${location.pathname}?${params}`);
  }

}
