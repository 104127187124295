import { Controller } from "@hotwired/stimulus"

// splits file input (multiple) into multiple nested form items
export default class extends Controller {
  static targets = [
    "addItemBtn"
  ]

  split(event) {
    const fileList = event.currentTarget.files

    Array.from(fileList).forEach(file => {
      let dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)

      this.element.addEventListener('nested-form:after-insert', event => {
        let targetInput = event.target.querySelector('input[type="file"]')
        targetInput.files = dataTransfer.files
        targetInput.dispatchEvent(new Event('change'))
      }, {once: true})

      this.addItemBtnTarget.click()
    })
  }
}
