import { get } from "@rails/request.js"
import { Controller } from "@hotwired/stimulus"
import Spinner from "lib/spinner"

// Dodaje opcje do selectów pól i podpól.
// Na `connect` pobiera listę pól i podpól schematu mapowanego.
// Dopasowuje tagi pól i kody podpól w formularzu.
// Po wybraniu pola dopasowuje kody podpól.
// example:
// <div
//   data-controller="fields-mapping"
//   data-fields-mapping-schema-id-value="5"
//   data-fields-mapping-schema-type-value="BibRecord"
//   <fieldset data-fields-mapping-target="field">
//     <button
//       data-action="fields-mapping#autoSelectField"
//       type="button">
//       Dopasuj
//     </button>
//     <select
//       data-fields-mapping-target="fieldSelect"
//       data-source-tag="200"
//       data-source-has-indicators="false">
//     </select>
//     <!-- subfield selects -->
//     <select data-fields-mapping-target="subfieldSelect" data-source-code="a"></select>
//     <!-- ... -->
//     <select data-fields-mapping-target="subfieldSelect" data-source-code="z"></select>
//   </fieldset>
//   <button
//     data-action="fields-mapping#autoSelectFieldAll" type="button">Dopasuj</button>
// </div>
export default class extends Controller {
  static values = {
    schemaId: String,
    schemaType: String
  }
  static targets = ["field", "fieldSelect", "subfieldSelect"]

  async connect() {
    Spinner.on()
    // load fields and subfields for mapping schema
    this.fields = await this.getFields()
    this.initSelect2()
    Spinner.off()
  }

  initSelect2() {
    this.select2Selector.on("change.select2", event => this.selectTag(event));
    this.select2Selector.on("select2:opening", event => this.onFieldClick(event));
    this.select2Selector.select2()
  }

  disconnect() {
    this.select2Selector.off("change");
  }

  get select2Selector() {
    return $(`[data-${this.identifier}-target="fieldSelect"]`)
  }

  async getFields(fields = [], page = 1) {
    const response = await get("/api/fields", {
      query: {
        "filter[schema_id_eq]": this.schemaIdValue,
        "filter[schema_type_eq]": this.schemaTypeValue,
        "include": "subfields",
        "page[number]": page,
        "page[size]": 150
      }
    })
    if (response.ok) {
      const body = await response.json
      if (body.links.next) {
        return await this.getFields(fields.concat(this.mapFields(body)), page + 1)
      } else {
        return fields.concat(this.mapFields(body))
      }
    }
  }

  mapFields(body) {
    return body.data.map(field => {
      return {
        id: field.id,
        tag: field.attributes.tag,
        hasIndicators: field.attributes.indicators,
        subfields: field.relationships.subfields.data.map(subfield => {
          const sf = body.included.find(el => (el.type == subfield.type && el.id == subfield.id))
          return {
            id: sf.id,
            code: sf.attributes.code
          }
        }).sort((a, b) => a.code.localeCompare(b.code))
      }
    })
  }

  onFieldClick(event) {
    const fieldSelect = event.currentTarget

    if (!fieldSelect.dataset.fieldsLoaded) {
      this.assignOptionsForFieldSelect(fieldSelect)
      fieldSelect.dataset.fieldsLoaded = true
    }
  }

  assignOptionsForFieldSelect(fieldSelect) {
    const sourceHasIndicators = fieldSelect.dataset.sourceHasIndicators === "true"
    const old_value = fieldSelect.value
    this.removeOptions(fieldSelect)
    this.addOption(fieldSelect, "", "")
    this.fields.forEach(field => {
      if (field.hasIndicators === true || field.hasIndicators === sourceHasIndicators) {
        this.addOption(fieldSelect, field.id, field.tag)
      }
    });
    fieldSelect.value = old_value
    $(fieldSelect).trigger("change.select2")
  }

  selectTag(event) {
    const fieldSelect = event.currentTarget
    const field = this.fields.find(field => field.id == fieldSelect.value)
    if (field) this.selectSubfields(field, fieldSelect)
  }

  beforeSelectSubfield(event) {
    this.assignOptionsForSubfieldSelect(
      event.currentTarget,
      this.fieldSelect(this.fieldFieldSet(event.currentTarget))
    )
  }

  assignOptionsForSubfieldSelect(subfieldSelect, fieldSelect) {
    if (fieldSelect.value) {
      // assign codes by value
      const currentCode = subfieldSelect.options[subfieldSelect.selectedIndex]?.text
      this.removeOptions(subfieldSelect)
      this.addOption(subfieldSelect, "", "")
      const subfields = this.fields.find(f => f.id == fieldSelect.value).subfields
      subfields.forEach(subfield => {
        this.addOption(subfieldSelect, subfield.id, subfield.code)
      });
      this.selectByText(subfieldSelect, currentCode)
    } else {
      this.removeOptions(subfieldSelect)
      this.addOption(subfieldSelect, "", "")
      subfieldSelect.options.selectedIndex = 0
    }
  }

  addOption(select, value, text) {
    const option = document.createElement('option')
    option.value = value
    option.textContent = text
    select.appendChild(option)
  }

  removeOptions(select) {
    select.textContent = ""
  }

  autoSelectFieldAll() {
    Spinner.on()
    setTimeout(() => {
      this.fieldSelectTargets.forEach(fieldSelect => this.selectField(fieldSelect))
      Spinner.off()
    }, 1)
  }

  autoSelectField(event) {
    this.selectField(this.fieldSelect(this.fieldFieldSet(event.currentTarget)))
  }

  selectField(fieldSelect) {
    const field = this.fields.find(field => field.tag == fieldSelect.dataset.sourceTag)
    if (!field) return
    if (fieldSelect.dataset.fieldsLoaded) {
      fieldSelect.value = field.id
    } else {
      this.removeOptions(fieldSelect)
      this.addOption(fieldSelect, field.id, field.tag)
      fieldSelect.options.selectedIndex = 0
    }
    this.selectSubfields(field, fieldSelect)
    $(fieldSelect).trigger("change.select2")
  }

  selectSubfields(field, fieldSelect) {
    const subfieldSelects = this.subfieldSelects(this.fieldFieldSet(fieldSelect))
    subfieldSelects.forEach(subfieldSelect => {
      const subfield = field.subfields.find(subfield => subfield.code == subfieldSelect.dataset.sourceCode)
      this.removeOptions(subfieldSelect)
      if (subfield) {
        this.addOption(subfieldSelect, subfield.id, subfield.code)
      } else {
        this.addOption(fieldSelect, "", "")
      }
      subfieldSelect.options.selectedIndex = 0
    })
  }

  fieldFieldSet(fieldSelect) {
    return fieldSelect.closest(`[data-${this.identifier}-target="field"]`)
  }

  fieldSelect(fieldFieldSet) {
    return fieldFieldSet.querySelector(`[data-${this.identifier}-target="fieldSelect"]`)
  }

  subfieldSelects(fieldFieldSet) {
    return fieldFieldSet.querySelectorAll(`[data-${this.identifier}-target="subfieldSelect"]`)
  }

  selectByText(select, text) {
    const index = Array.apply(null, select.options).findIndex(o => o.textContent == text)
    if (index) select.options.selectedIndex = index
  }
}
