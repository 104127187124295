export default class {
  static settings = {
    "hotkeysEnabled": true,
    "nav.currentTab": "nav_elaboration",
    "nav.open": true
  }

  static getValue(key) {
    if (key in this.settings) {
      const storage = localStorage.getItem(key)
      return (storage === null) ? this.settings[key] : JSON.parse(storage).value
    } else {
      console.warn("LocalSettings: key not exist")
    }
  }

  static setValue(key, value) {
    if (key in this.settings) {
      localStorage.setItem(
        key,
        JSON.stringify({ value: value })
      )
    } else {
      console.warn("LocalSettings: key not exist")
    }
  }
}