import { Controller } from "@hotwired/stimulus"
import marked from "marked"

export default class extends Controller {
  static targets = [
    "input",
    "output"
  ]

  connect() {
    this.start()
  }

  start() {
    if (this._started === undefined && this._element_visible(this.outputTarget)) {
      this.sync()
      this._started = true
    }
    return this._started;
  }

  _element_visible(el) {
    return el.offsetWidth > 0 && el.offsetHeight > 0;
  }

  sync() {
    const content = marked(this.inputTarget.value)
    this.outputTarget.innerHTML = content
    this.outputTarget.addEventListener('scroll', () => {
      this.syncScrollY(this.outputTarget, this.inputTarget)
    }, { passive: true })
    this.onInputScroll()
  }

  onInputScroll() {
    this.syncScrollY(this.inputTarget, this.outputTarget)
  }

  syncScrollY(a, b) {
    if (a.syncedScrollTop !== a.scrollTop) {
      const rate = a.scrollTop / (a.scrollHeight - a.clientHeight)
      const scroll = Math.round(rate * (b.scrollHeight - b.clientHeight))
      b.syncedScrollTop = scroll
      b.scrollTop = scroll
    }
  }
}
