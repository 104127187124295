import { Controller } from "@hotwired/stimulus"

// example:
// <select data-controller="select2">
//    <option value="0">Dyscyplina</option>
//  </select>
export default class extends Controller {

  connect() {
    this.element.select2 = this
    $(this.element).on("change", () => {
      this.element.dispatchEvent(new Event("select2:change", { "bubbles": true }))
    });
    this.init();
  }

  disconnect() {
    $(this.modalTarget).off("change");
  }

  setValue(value) {
    $(this.element).val(value).trigger("change").trigger("select2:select");
  }

  init() {
    $(this.element).select2()
  }
}
