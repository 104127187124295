import { Controller } from "@hotwired/stimulus"

// example:
// <div
//   data-action="direct-upload:initialize->direct-upload#initialize direct-upload:start->direct-upload#start direct-upload:progress->direct-upload#progress direct-upload:error->direct-upload#error direct-upload:end->direct-upload#end"
//   data-controller="direct-upload">
//   <input type="file" name="file"
//     data-direct-upload-url="http://localhost:9000/rails/active_storage/direct_uploads">
// </div>
export default class extends Controller {
  initialize(event) {
    if (event) {
      const { target, detail } = event

      const { id } = detail
      const progressBar =
        ` <div id="direct-upload-${id}" class="progress">
          <div id="direct-upload-progress-${id}" class="progress-bar progress-bar-animated progress-bar-striped" style="width: 0%"></div>
        </div>`
      const container = target
      container.insertAdjacentHTML("afterend", progressBar)
    }
  }

  start(event) {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  }

  progress(event) {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
    progressElement.innerText = `${+progress.toFixed(2)}%`
  }

  error(event) {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.classList.remove("progress-bar-animated", "progress-bar-striped")
    progressElement.innerText = error
  }

  end(event) {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  }
}
