import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  get checkboxTargets () {
    return this.element.querySelectorAll('input[type="checkbox"]')
  }

  clearAll () {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
      const outerLabel = checkbox.closest('label')
      if (outerLabel) outerLabel.classList.remove('active')
    })
  }

  selectAll () {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
      const outerLabel = checkbox.closest('label')
      if (outerLabel) outerLabel.classList.add('active')
    })
  }
}
