// zagnieżdzony formularz
// `addItem` dodaje template na końcu 'items', podmieniając `itemId` losową liczbą
// generuje eventy: `nested-form:after-insert` i `nested-form:after-remove`
//
// example:
// <div data-controller="c">
//   <template data-c-target="template"> item nr TEMPLATE_RECORD</template>
//   <div data-c-target="items"></div>
//   <a data-action="c#addItem" href="#">Add item</a>
// </div>


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "template", // html template element used to create new item
    "items", // items container
    "item", // existing items
  ]

  itemId() {
    return this.data.get('itemId') || 'TEMPLATE_RECORD';
  }

  replaceItemId(templateTarget) {
    this.generatedIds ||= new Set();
    let generatedId;
    do {
      generatedId = new Date().valueOf();
    } while (this.generatedIds.has(generatedId));

    this.generatedIds.add(generatedId)
    return templateTarget.innerHTML.replace(
      new RegExp(this.itemId(), 'g'),
      generatedId
    );
  }

  addItem(event) {
    event.preventDefault();
    const content = this.replaceItemId(this.templateTarget);
    this.itemsTarget.insertAdjacentHTML('beforeend', content);

    this.afterAddItem(this.itemsTarget.lastElementChild);
  }

  afterAddItem(newItem) {
    let afterInsertEvent = new Event('nested-form:after-insert', { "bubbles": true })
    newItem.dispatchEvent(afterInsertEvent)
    this.focusNewInput(newItem);
  }

  onRemoveItem(event) {
    event.preventDefault()
    // check ujs :confirm response
    if (event.detail[0] === false) return;
    let item = this.itemTargets.find((item) => item.contains(event.target));
    this.constructor.removeItem(item)
  }

  static getDestroyInput(item) {
    return item.querySelector("input[name$='[_destroy]']")
  }

  static removeItem(item) {
    if (this.getDestroyInput(item)) {
      this.getDestroyInput(item).value = 1;
      item.style.display = 'none';
      this.afterRemoveItem(item);
    } else {
      const parentEl = item.parentElement
      item.remove()
      this.afterRemoveItem(parentEl);
    }
  }

  removeAll() {
    this.itemTargets.forEach((item) => this.constructor.removeItem(item));
  }

  static itemMarkedForDestruction(item) {
    return this.getDestroyInput(item).value === "1"
  }

  static afterRemoveItem(removedItem) {
    let afterRemoveEvent = new Event('nested-form:after-remove', { "bubbles": true })
    removedItem.dispatchEvent(afterRemoveEvent);
  }

  focusNewInput(newItem) {
    if (!newItem.querySelector) return
    const newInput = newItem.querySelector('input:not([type="hidden"])')
    setTimeout(() => {
      if (newInput) {
        newInput.focus()
      } else {
        newItem.focus();
      }
    });
  }
}
